<template>
  <section class="blank-page">
  </section>
</template>

<script>
export default {
  name: "Home",
};
</script>
